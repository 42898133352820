import services from "./index";
import store from "@/store";
import qs from "querystring";

export default {
    getConfig() {
        return {
            headers: {
                authorization: `Bearer ${store.getters.jwtToken}`,
                //"x-tenant-id": process.env.VUE_APP_TENANT,
            }
        };
    },
    
    get(id) {
        return services.http.get(`/v1.0/profile/groups/${id}`, this.getConfig());
    },
    
    list(type, limit) {
        return services.http.post(`/v1.0/groups/list?type=${type||''}&limit=${limit||''}`, {}, this.getConfig());
    },
    
    
    getGroupsInEvent(eventId, category) {
        return services.http.get(`/v1.0/groups/events/${eventId}/groups?category=${category||''}`, this.getConfig());
    },

}